import React, { useState, useEffect } from 'react';
import { client } from 'cccisd-apollo';
import { RegisterLoginPage } from 'cccisd-laravel-nexus';
import Loader from 'cccisd-loader';
import { Redirect, useParams, useHistory } from 'cccisd-react-router';
import query from './group.graphql';
import { getHomeRoute } from '../../helpers';

const Fortress = window.cccisd.fortress;

const Register = () => {
    const { hash } = useParams();
    if (!hash) {
        return <Redirect to="/" />;
    }

    const [isLoading, setIsLoading] = useState(true);
    const [groupId, setGroupId] = useState(null);
    const history = useHistory();

    useEffect(() => {
        async function getData() {
            const resp = await client.query({
                query,
                variables: {
                    hash,
                },
                fetchPolicy: 'network-only',
            });

            setGroupId(resp.data.groups?.organization?.group?.groupId);
            setIsLoading(false);
        }

        if (hash) {
            getData();
        }
    }, []);

    if (isLoading) {
        return <Loader loading />;
    }

    if (!groupId) {
        return (
            <div className="alert alert-warning">
                <h3>Incorrect Registration Link</h3>
                <p>Please contact your Organization administrator for support.</p>
                <p>
                    If you feel this message is shown in error, you can contact 3C Support at{' '}
                    <a href="mailto:support@3cisd.com">support@3cisd.com</a>.
                </p>
            </div>
        );
    }

    if (Fortress.auth()) {
        return (
            <div className="alert alert-warning">
                <p>You must be logged out to use the registration page.</p>
                <br />
                <button type="button" onClick={() => history.push(getHomeRoute())} className="btn btn-primary">
                    Go Home
                </button>
            </div>
        );
    }

    const registerLoginProps = {
        registerProps: {
            role: 'professional',
            group: groupId,
            showPasswordFields: true,
        },
    };
    return <RegisterLoginPage {...registerLoginProps} />;
};

export default Register;
