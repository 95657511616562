import React from 'react';
import copy from 'copy-to-clipboard';
import IconCopy from 'cccisd-icons/copy';
import { Manage as AppdefManage } from 'cccisd-laravel-appdefs';
import notification from 'cccisd-notification';
import { useLocation } from 'cccisd-react-router';
import Tooltip from 'cccisd-tooltip';

const Appdefs = window.cccisd.appDefs;
const Boilerplate = window.cccisd.boilerplate;
const Fortress = window.cccisd.fortress;

const Manage = props => {
    const { hash: selectedTab } = useLocation();
    const hash = Fortress.user.acting?.group?.random_hash;
    const link = Boilerplate.url('/register/' + hash);

    function copyLink() {
        copy(link);
        notification({ message: 'Copied!', type: 'success', duration: 3000 });
    }

    function renderGroupRegistrationLink() {
        if (!hash || !selectedTab.includes('professional')) {
            return;
        }

        return (
            <div className="alert alert-info">
                Add <b>{Appdefs.pawn.roles.find(r => r.handle === 'professional').labelPlural}</b> using the form below.
                Alternatively, they can register themselves at{' '}
                <Tooltip title="Copy to clipboard">
                    <a
                        href="#"
                        onClick={e => {
                            e.preventDefault();
                            copyLink();
                        }}
                    >
                        {link}
                    </a>
                </Tooltip>
                &nbsp;&nbsp;
                <Tooltip title="Copy to clipboard">
                    <button className="btn btn-info btn-xs" onClick={copyLink} type="button">
                        <IconCopy />
                    </button>
                </Tooltip>
            </div>
        );
    }

    return (
        <div>
            <h1>Manage</h1>
            {renderGroupRegistrationLink()}
            <AppdefManage {...props} />
        </div>
    );
};

export default Manage;
