import React from 'react';
import copy from 'copy-to-clipboard';
import IconCopy from 'cccisd-icons/copy';
import notification from 'cccisd-notification';

const Boilerplate = window.cccisd.boilerplate;

export default settings => {
    return props => {
        const { value } = props;

        function copyToClipboard() {
            const url = Boilerplate.url('/register/' + value);

            copy(url);
            notification({
                message: 'Copied to clipboard!',
                type: 'success',
                duration: 4000,
            });
        }

        return (
            <button type="button" className="btn btn-xs btn-info" onClick={copyToClipboard}>
                <IconCopy spaceRight />
                Copy
            </button>
        );
    };
};
